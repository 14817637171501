 
<template>
  <v-main class="grey lighten-3">
   
   


  </v-main>
</template>

<script>

 

export default ({

   data() {
    return {

     
    }},

  
  methods: {
    
   
     

  },
  mounted() {

    console.log(this.$route.params.QuestID);
    //this.resultMessage = this.$route.params.QuestID;
     
  },
})
</script>
